import React, { FC, useCallback, useContext } from 'react';
import { useNavigate, useParams } from 'react-router';
import { styled } from '@linaria/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import Logo from './Logo';
import { getFilmWithMyProviders } from '../services/api';
import { center, StyledText } from '../stylesheets/shared.css';
import { electricBlue, pink } from '../stylesheets/colors.css';
import { SelectedProvidersContext, UsernameContext } from './ContextProvider';
import Providers from './Providers';
import ErrorBoundary from './errors/ErrorBoundary';
import Container from './Container';

const Center = styled.div`
  ${center}
  width: 30%;
  max-height: 90px;
`;

const Poster = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 450px;
  margin-top: 15px;
  box-shadow: 7px 7px 0 0 ${electricBlue}, 7px 7px 10px 1px ${electricBlue}, -7px -7px 0 0 ${pink}, -7px -7px 10px 1px;
`;

const MovieData = styled.div`
  width: calc(100% - 2 * 30px);
  margin: 30px;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  background-color: rgb(50 50 50 / 70%);
  box-shadow: 0 0 10px 0 rgb(50 50 50);
  overflow-wrap: break-word;
`;

const Again = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent;
  appearance: none;
  cursor: pointer;
`;

const Result: FC = () => {
  const { filmId } = useParams();
  const [username] = useContext(UsernameContext);
  const [selectedProviders] = useContext(SelectedProvidersContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { isLoading, data: movie } = useQuery(
    ['filmDetails', parseInt(filmId!, 10), selectedProviders],
    getFilmWithMyProviders,
  );

  const wipeFilm = useCallback(() => {
    queryClient.removeQueries({ queryKey: ['randomWatchableFilm', username, selectedProviders] });
    navigate('/');
  }, [queryClient, username, selectedProviders, navigate]);

  if (isLoading || !movie) {
    return null;
  }

  return (
    <Container>
      <ErrorBoundary>
        <Center>
          <Again onClick={wipeFilm}>
            <Logo />
          </Again>
        </Center>
        <Poster alt="Movie poster" src={`https://www.themoviedb.org/t/p/w600_and_h900_bestv2/${movie.poster_path}`} />
        <MovieData>
          <StyledText size="36px">
            {movie.title} ({movie.release_date.split('-')[0]})
          </StyledText>
          <Providers providers={movie.mySources} />
          <StyledText size="10px">Streaming API data provided by JustWatch</StyledText>
        </MovieData>
      </ErrorBoundary>
    </Container>
  );
};

export default Result;
