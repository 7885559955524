import React, { ReactNode } from 'react';
import { styled } from '@linaria/react';

const Body = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-image: url('../assets/images/outrun_nosun.jpeg');
  background-size: cover;
  background-position: 50% 75%;
  background-attachment: fixed;
`;

export default ({ children }: { children: ReactNode }) => <Body>{children}</Body>;
