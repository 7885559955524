/* eslint-disable import/no-import-module-exports */

import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Analytics from './components/Analytics';
import Routes from './components/Routes';
import Layout from './components/Layout';
import { setup as sentrySetup } from './services/errors';
import CommonMeta from './components/CommonMeta';
import ContextProvider from './components/ContextProvider';

// eslint-disable-next-line
import 'stylesheets/global.css.ts';

sentrySetup();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const rootEl = document.getElementById('main');
const root = createRoot(rootEl!);
const render = () => {
  root.render(
    <StrictMode>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Analytics />
            <CommonMeta />
            <ContextProvider>
              <Layout>
                <Routes />
              </Layout>
            </ContextProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </HelmetProvider>
    </StrictMode>,
  );
};

if (module.hot) {
  module.hot.accept('./components/Routes', () => {
    render();
  });
}

render();
