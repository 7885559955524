/* eslint-disable camelcase */

import { styled } from '@linaria/react';
import React, { FC, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';

import { electricBlue } from '../stylesheets/colors.css';
import type { PromptProps } from './Prompt';
import { Provider } from '../services/api';

interface ProvidersProps {
  providers: Provider[];
  selectedProviders?: PromptProps['selectedProviders'];
  dispatchSelectedProviders?: PromptProps['dispatchSelectedProviders'];
}

const ProviderGrid = styled.div<{ items: number }>`
  display: grid;
  grid-template-columns: repeat(${({ items }) => Math.min(items, 5)}, 1fr);
  grid-auto-rows: 50px;
  gap: 15px;
  justify-content: space-between;
  place-items: center;
  margin: 25px 0;
`;

const ProviderNode = styled.div<{ clickable: boolean }>`
  border-radius: 10px;
  width: 50px;
  height: 50px;

  &:hover {
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  }

  &.active {
    box-shadow: 0 0 0 3px ${electricBlue}, 0 0 10px 3px ${electricBlue};
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: unknown;
  wrapper: (c: ReactNode) => ReactElement;
  children: ReactElement;
}) => (condition ? wrapper(children) : children);

// @TODO: Deeplinking into different services?
const link = undefined;

const Providers: FC<ProvidersProps> = ({ providers, selectedProviders, dispatchSelectedProviders }) => (
  <ProviderGrid items={providers.length}>
    {providers.map(({ provider_id, provider_name, logo_path }) => (
      <ProviderNode
        key={provider_id}
        className={clsx({ active: selectedProviders && selectedProviders[provider_id] })}
        clickable={link || !!dispatchSelectedProviders}
        onClick={() => {
          if (dispatchSelectedProviders) {
            dispatchSelectedProviders({ type: 'toggle', payload: provider_id });
          }
        }}
      >
        <ConditionalWrapper
          condition={link}
          // eslint-disable-next-line react/no-unstable-nested-components
          wrapper={children => <a href={link!}>{children}</a>}
        >
          <img alt={provider_name} src={`https://themoviedb.org/t/p/original/${logo_path}`} />
        </ConditionalWrapper>
      </ProviderNode>
    ))}
  </ProviderGrid>
);

export default Providers;
