import { styled } from '@linaria/react';

import { electricBlue } from './colors.css';

export const center = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
};

export const robotoLight = {
  fontFamily: "'Roboto', sans-serif",
  fontWeight: 100,
};

export const roboto = {
  fontFamily: "'Roboto', sans-serif",
  fontWeight: 400,
};

export const hardShadow = (color: string) => ({
  textShadow: `1px 1px 1px ${color}`,
});

export const StyledText = styled.span<{ size?: string }>`
  ${roboto}
  ${hardShadow(electricBlue)}
  color: white;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 2.2px;
  font-size: ${props => props.size || '16px'};
`;
