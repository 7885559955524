import { QueryFunction } from '@tanstack/react-query';
import type { Responses, Objects } from 'node-themoviedb';

import { RENTAL_PROVIDERS, TMDB_API_ROOT } from '../constants';

type TMDBFilm = Objects.Movie;
export type Provider = Objects.MovieWatchProvider['flatrate'][0];
export type TMDBFilmWithMySources = TMDBFilm & { mySources: Provider[] };
interface GetAllWatchProvidersUS {
  results: Array<Provider & { display_priorities: Record<string, number> }>;
}

function safeFetch<T>(url: RequestInfo | URL, options: RequestInit) {
  return fetch(url, options).then(response => {
    if (!response.ok) {
      throw new Error(response.statusText);
    } else {
      return response.json() as T;
    }
  });
}

const getWatchlistedFilm = async (username: string, signal?: AbortSignal) =>
  // kekw. shouldn't be doing this. naughty
  safeFetch<LetterboxdFilm>(`https://watchlistpicker.com/api?users=${username}`, {
    signal,
    headers: {
      accept: '*/*',
      'accept-language': 'en-US,en;q=0.9',
      'sec-ch-ua': '"Google Chrome";v="105", "Not)A;Brand";v="8", "Chromium";v="105"',
      'sec-ch-ua-mobile': '?0',
      'sec-ch-ua-platform': '"macOS"',
      'sec-fetch-dest': 'empty',
      'sec-fetch-mode': 'cors',
      'sec-fetch-site': 'same-origin',
      'sec-gpc': '1',
    },
    referrer: `https://watchlistpicker.com/?u=${username}`,
    referrerPolicy: 'strict-origin-when-cross-origin',
    body: null,
    method: 'GET',
    mode: 'cors',
    credentials: 'omit',
  });

const getLetterboxdFilmTmbd = async (film: LetterboxdFilm, signal?: AbortSignal) => {
  const searchResult = await safeFetch<Responses.Search.Movies>(
    `${TMDB_API_ROOT}/search/movie?${new URLSearchParams({
      query: film.film_name,
      year: film.release_year,
    })}`,
    { signal },
  );
  return searchResult.results[0];
};

const getFilm = async (filmId: TMDBFilm['id'], signal?: AbortSignal) => {
  const response = await safeFetch<Responses.Movie.GetDetails>(`${TMDB_API_ROOT}/movie/${filmId}`, {
    signal,
  });

  return {
    ...response,
    genre_ids: [],
  } as Objects.Movie;
};

const getSourcesForFilm = async (film: TMDBFilm, signal?: AbortSignal) => {
  const response = await safeFetch<Responses.Movie.GetWatchProviders>(
    `${TMDB_API_ROOT}/movie/${film.id}/watch/providers`,
    {
      signal,
    },
  );

  // @TODO: Make the region configurable?
  return {
    subscription: response.results.US.flatrate,
    rental: response.results.US.rent,
  };
};

const findSourcesUnion = (filmSources: Provider[], mySources: Record<number, boolean>): Provider[] =>
  filmSources.filter(source => !!mySources[source.provider_id]);

export const findWatchableWatchlistFilm: QueryFunction<
  TMDBFilmWithMySources | undefined,
  [string, string | undefined, Record<number, boolean> | undefined]
> = async ({ queryKey, signal }) => {
  const [__, username, mySources] = queryKey;

  if (!mySources) {
    return Promise.resolve(undefined);
  }

  let watchableFilm: TMDBFilmWithMySources | null = null;
  let error: Error | null = null;

  /* eslint-disable no-await-in-loop */
  while (!watchableFilm && !error) {
    try {
      const candidateFilm = await getWatchlistedFilm(username || 'bjacobel', signal);
      const candidateFilmTmdb = await getLetterboxdFilmTmbd(candidateFilm, signal);
      const sourcesForCandidate = await getSourcesForFilm(candidateFilmTmdb, signal);
      const rentalAndSubscriptSources = [
        ...(sourcesForCandidate.rental || []),
        ...(sourcesForCandidate.subscription || []),
      ];
      const sourcesUnion = findSourcesUnion(rentalAndSubscriptSources, mySources);

      if (sourcesUnion.length) {
        watchableFilm = {
          ...candidateFilmTmdb,
          mySources: sourcesUnion,
        };
      }
    } catch (e) {
      error = e as Error;
    }
  }
  /* eslint-enable no-await-in-loop */

  if (error) {
    throw error;
  }

  return watchableFilm!;
};

export type RentalOrSubProvider = Provider & { rental: boolean };

export const getProviders: QueryFunction<Array<RentalOrSubProvider>, [string]> = async ({ signal }) => {
  const getWatchProvidersResponse = await safeFetch<GetAllWatchProvidersUS>(
    `${TMDB_API_ROOT}/watch/providers/movie?watch_region=US`,
    { signal },
  );

  return getWatchProvidersResponse.results
    .sort((p1, p2) => p1.display_priorities.US - p2.display_priorities.US)
    .filter(prov => prov.provider_name !== 'HBO Max' && !/Channel/.test(prov.provider_name))
    .map(prov => ({ ...prov, rental: RENTAL_PROVIDERS.includes(prov.provider_name) }))
    .filter(prov => prov.rental || prov.display_priorities.US < 15);
};

export const getFilmWithMyProviders: QueryFunction<
  TMDBFilmWithMySources | undefined,
  [string, TMDBFilm['id'], Record<number, boolean>]
> = async ({ queryKey, signal }) => {
  const [__, filmId, mySources] = queryKey;

  const film = await getFilm(filmId, signal);
  const sources = await getSourcesForFilm(film, signal);

  const rentalAndSubscriptSources = [...(sources.rental || []), ...(sources.subscription || [])];
  const sourcesUnion = findSourcesUnion(rentalAndSubscriptSources, mySources);

  return {
    ...film,
    mySources: sourcesUnion,
  };
};
