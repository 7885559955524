import React, { FC, ReactNode, SetStateAction, Dispatch, createContext, useState, useReducer } from 'react';

type ProviderAction = { type: 'toggle' | 'set'; payload: number };

export const UsernameContext = createContext<[string | undefined, Dispatch<SetStateAction<string | undefined>>]>([
  undefined,
  () => undefined,
]);
export const SelectedProvidersContext = createContext<[Record<number, boolean>, Dispatch<ProviderAction>]>([
  [],
  () => undefined,
]);

const providerReducer = (state: Record<number, boolean>, action: ProviderAction) => {
  const newState = { ...state };

  if (newState[action.payload] && action.type === 'toggle') {
    delete newState[action.payload];
  } else {
    newState[action.payload] = true;
  }

  return newState;
};

type ContextProviderProps = {
  children?: ReactNode;
};

/**
 * I'm just being hard-headed and refusing to install Redux
 */
const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
  const usernameState = useState<string | undefined>();
  const selectedProvidersReducer = useReducer(providerReducer, {});

  return (
    <UsernameContext.Provider value={usernameState}>
      <SelectedProvidersContext.Provider value={selectedProvidersReducer}>{children}</SelectedProvidersContext.Provider>
    </UsernameContext.Provider>
  );
};
export default ContextProvider;
