import React, { FC, useEffect, useMemo, useState } from 'react';
import { styled } from '@linaria/react';

import { electricBlue } from '../stylesheets/colors.css';

interface LoadingSunProps {
  size?: number;
}

interface ProgressRingProps {
  radius: number;
  progress: number;
}

const SunContainer = styled.div<LoadingSunProps>`
  background-image: url('../assets/images/bigsun.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: ${props => props.size!}px;
  width: ${props => props.size!}px;
  display: block;
`;

const RingHelper = styled.div<{ radius: number }>`
  transform: rotate(-90deg) scale(110%);
  transform-origin: 50% 50%;
  height: ${({ radius }) => radius * 2}px;
  width: ${({ radius }) => radius * 2}px;

  svg circle {
    transition: stroke-dashoffset linear 0.15s;
  }
`;

const ProgressRing: FC<ProgressRingProps> = ({ radius, progress }) => {
  const stroke = 2;
  const normalizedRadius = useMemo(() => radius - stroke * 2, [radius]);
  const circumference = useMemo(() => normalizedRadius * 2 * Math.PI, [normalizedRadius]);
  const strokeDashoffset = useMemo(() => circumference - (progress / 100) * circumference, [circumference, progress]);

  return (
    <RingHelper radius={radius}>
      <svg height={radius * 2} width={radius * 2}>
        <filter id="blur">
          <feGaussianBlur result="coloredBlur" stdDeviation="2" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <circle
          stroke={electricBlue}
          fill="transparent"
          strokeWidth={stroke}
          filter="url(#blur)"
          strokeDasharray={`${circumference} ${circumference}`}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </RingHelper>
  );
};

const LoadingSun: FC<LoadingSunProps> = ({ size }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const radius = useMemo(() => (size || 50) / 2, [size]);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let subscribed = true;
    let interval: NodeJS.Timer;
    const kill = () => {
      clearInterval(interval);
      subscribed = false;
    };
    interval = setInterval(() => {
      if (progress > 89.5) return;
      if (subscribed) {
        setProgress(progress + (100 - 10 - progress) / 50);
      }
    }, 100);
    return kill;
  });

  useEffect(() => {
    let subscribed = true;
    let timeout: NodeJS.Timer;
    const kill = () => {
      clearInterval(timeout);
      subscribed = false;
    };
    timeout = setTimeout(() => {
      if (subscribed) {
        setShouldRender(true);
      }
    }, 100);
    return kill;
  });

  // Avoid flash of loading spinner. If wait is less than 200ms, just render nothing.
  if (!shouldRender) {
    return null;
  }

  return (
    <SunContainer size={size}>
      <ProgressRing radius={radius} progress={progress} />
    </SunContainer>
  );
};

LoadingSun.defaultProps = {
  size: 50,
};

export default LoadingSun;
