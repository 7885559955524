import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NotFound from './NotFound';
import Home from './Home';
import Result from './Result';

export default () => (
  <Routes>
    <Route element={<Home />} path="/" />
    <Route element={<Result />} path="/watch/:filmId" />
    <Route element={<NotFound />} path="*" />
  </Routes>
);
