import React from 'react';
import { styled } from '@linaria/react';

const Header = styled.h1`
  background-image: url('../assets/images/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 100px;
  width: 100%;
`;

export default () => <Header />;
