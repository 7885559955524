export const TRACK_ANALYTICS = false;
export const LOG_ERRORS = process.env.NODE_ENV === 'production';
export const SHOW_STACK = process.env.NODE_ENV !== 'production';
export const RAVEN_ENDPT = projectConfig.RavenDSN;
export const GA_ID = projectConfig.GAProperty;
export const RELEASE = process.env.GITHUB_SHA;
export const TMDB_API_ROOT = 'https://c4d8ixjgoe.execute-api.us-east-1.amazonaws.com/prod';
export const DEFAULT_PROVIDERS = ['Netflix', 'Amazon Prime Video', 'Hulu', 'Apple TV Plus', 'Max'];

// annoying that this is not provided by the API. Maybe a different API would have this, but then we'd have to reconcile ids
export const RENTAL_PROVIDERS = ['Apple TV', 'Amazon Video', 'Google Play Movies', 'YouTube'];
