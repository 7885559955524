import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import Prompt from './Prompt';
import LoadingSun from './LoadingSun';
import { findWatchableWatchlistFilm, getProviders } from '../services/api';
import ErrorComponent from './errors/ErrorComponent';
import ErrorBoundary from './errors/ErrorBoundary';
import { DEFAULT_PROVIDERS } from '../constants';
import { SelectedProvidersContext, UsernameContext } from './ContextProvider';
import Container from './Container';

export default () => {
  const navigate = useNavigate();
  const [username, setUsername] = useContext(UsernameContext);
  const [selectedProviders, dispatchSelectedProviders] = useContext(SelectedProvidersContext);
  const [queryReady, setQueryReady] = useState(false);
  const { isLoading: providersLoading, error, data: providers } = useQuery(['providers'], getProviders);
  const { isLoading: movieLoading, isInitialLoading, data: movieToWatchWithServices } = useQuery(
    ['randomWatchableFilm', username, selectedProviders],
    findWatchableWatchlistFilm,
    {
      enabled: queryReady,
    },
  );

  useEffect(() => {
    if (!providers) return;

    DEFAULT_PROVIDERS.forEach(name => {
      const provider = providers.find(p => p.provider_name === name);
      if (provider) {
        dispatchSelectedProviders({ type: 'set', payload: provider.provider_id });
      }
    });
  }, [providers, dispatchSelectedProviders]);

  useEffect(() => {
    if (movieToWatchWithServices) {
      navigate(`/watch/${movieToWatchWithServices.id}`);
    }
  }, [movieToWatchWithServices, navigate]);

  if (providersLoading || (queryReady && movieLoading) || isInitialLoading) {
    return (
      <Container valign="center">
        <LoadingSun size={200} />
      </Container>
    );
  }

  if (error && error instanceof Error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <Container>
      <ErrorBoundary>
        <Prompt
          allProviders={providers!}
          selectedProviders={selectedProviders}
          dispatchSelectedProviders={dispatchSelectedProviders}
          setUsername={setUsername}
          fireQuery={() => setQueryReady(true)}
        />
      </ErrorBoundary>
    </Container>
  );
};
