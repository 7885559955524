/* eslint-disable camelcase */

import React, { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { styled } from '@linaria/react';

import Logo from './Logo';
import Providers from './Providers';
import { center, robotoLight, StyledText } from '../stylesheets/shared.css';
import { electricBlue, pink } from '../stylesheets/colors.css';
import { RentalOrSubProvider } from '../services/api';

const Center = styled.div`
  ${center}
  width: 100%;
  margin-top: 40px;
  margin-bottom: 15px;
`;

const Username = styled.input`
  height: 60px;
  ${robotoLight}
  font-size: 25px;
  letter-spacing: 1.8px;
  width: 100%;
  padding: 8px 12px;
  border-style: none;
  border-radius: 0;
  outline-color: transparent;
  background-color: transparent;
  border-bottom: 1px solid white;
  transition: all 0.3s ease;
  color: white;
  clip-path: polygon(0 1px, 0 105%, 100% 105%, 100% 1px);

  ::placeholder {
    color: white;
    opacity: 0.8;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px 0 ${electricBlue};
    border-bottom: 1px solid ${electricBlue};
  }
`;

const Go = styled.button`
  text-transform: uppercase;
  font-style: italic;
  font-size: 30px;
  text-shadow: 2px 2px 4px rgb(100 100 100 / 40%);
  color: white;
  background-color: ${pink};
  height: 70px;
  width: 160px;
  border: none;
  border-radius: 3px;
  margin: 0 auto 50px auto;
  box-shadow: 3px 3px 0 0 ${electricBlue}, 3px 3px 8px 0 ${electricBlue};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translate(1px, 1px);
    box-shadow: 2px 2px 0 0 ${electricBlue}, 2px 2px 10px 0 ${electricBlue};
  }
`;

const LbLabel = styled.label`
  margin: 20px 0 10px 0;
  padding: 0 30px;
  width: 100%;

  span {
    margin-left: 12px;
    margin-bottom: 8px;
  }
`;

export interface PromptProps {
  allProviders: RentalOrSubProvider[];
  selectedProviders: Record<number, boolean>;
  dispatchSelectedProviders: (action: { type: 'toggle' | 'set'; payload: number }) => void;
  setUsername: (username: string) => void;
  fireQuery?: () => void;
}

// @TODO: validation (should not be able to submit with empty username)
const Prompt: FC<PromptProps> = ({
  setUsername: passUsernameUp,
  fireQuery,
  allProviders,
  selectedProviders,
  dispatchSelectedProviders,
}) => {
  const [username, setUsername] = useState<string>('');

  const [streamingServices, rentalServices] = useMemo(() => {
    const streaming: RentalOrSubProvider[] = [];
    const rental: RentalOrSubProvider[] = [];
    allProviders.forEach(provider => {
      if (provider.rental) {
        rental.push(provider);
      } else {
        streaming.push(provider);
      }
    });
    return [streaming, rental];
  }, [allProviders]);

  const usernameOnChange = (evt: SyntheticEvent<HTMLInputElement>) => {
    setUsername(evt.currentTarget.value);
  };

  useEffect(() => {
    passUsernameUp(username);
  }, [username, passUsernameUp]);

  return (
    <>
      <Center>
        <Logo />
      </Center>
      <LbLabel>
        <StyledText>Letterboxd Username</StyledText>
        <Username placeholder="bjacobel" value={username} onChange={usernameOnChange} />
      </LbLabel>
      <LbLabel>
        <StyledText>Streaming Services</StyledText>
        <Providers
          providers={streamingServices}
          selectedProviders={selectedProviders}
          dispatchSelectedProviders={dispatchSelectedProviders}
        />
      </LbLabel>
      <LbLabel>
        <StyledText>Rental Services</StyledText>
        <Providers
          providers={rentalServices}
          selectedProviders={selectedProviders}
          dispatchSelectedProviders={dispatchSelectedProviders}
        />
      </LbLabel>
      <Go type="submit" onClick={fireQuery}>
        Go
      </Go>
    </>
  );
};

Prompt.defaultProps = {
  fireQuery: () => {
    // noop
  },
};

export default Prompt;
