import { styled } from '@linaria/react';

interface ContainerProps {
  valign?: 'center';
}

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${props => props.valign || 'flex-start'};
  flex-direction: column;
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
  background-color: rgba(100 100 100 30%);
`;

export default Container;
