import { styled } from '@linaria/react';
import React, { FC } from 'react';

import { SHOW_STACK } from '../../constants';
import { roboto } from '../../stylesheets/shared.css';

const MonoHeader = styled.h3`
  ${roboto}
`;

interface ErrorComponentProps {
  error?: Error;
}

const ErrorComponent: FC<ErrorComponentProps> = ({ error }) => (
  <>
    <MonoHeader>Something went wrong.</MonoHeader>
    {SHOW_STACK && error && error.stack && <pre data-testid="stack">{error.stack}</pre>}
  </>
);

ErrorComponent.defaultProps = {
  error: undefined,
};

export default ErrorComponent;
